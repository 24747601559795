const mqttHost = '47.114.51.90';
//const mqttHost = 'WIN-8HUH0P8M4P4';
const mqttPort = 8083;
const mqttUserName = 'admin';
const mqttPassword = 'admin';
//const mqttPassword = 'admin666';
const initUrl = 'http://121.199.23.236';
//const initUrl = 'http://192.168.1.3:9095';

export default {
    mqttHost,
    mqttPort,
    mqttUserName,
    mqttPassword,
    initUrl
}
