import Vue from "vue";
import VueRouter from "vue-router";
import form from "@/views/sb/form";
import forms from "@/views/sb/form_s";

//解决重复点击路由报错的Bug begin
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//end

Vue.use(VueRouter)

const routes=[
    {path:'/form',component: form},
    {path:'/forms',component: forms},
]
const router=new VueRouter({
    routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router
