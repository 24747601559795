<template>
    <div class="container bodyCss" style="display: block" id="mainbody">
        <div class="row head">
            <div class="col-md-1"></div>
            <div class="col-md-2 head_line" style="margin-right: 0"></div>
            <div class="col-md-6 head_title" id="topNmae">河南社保电子表单在线验证平台</div>
            <div class="col-md-2 head_line"></div>
            <div class="col-md-1"></div>
        </div>
        <div id="descript" class="descript">
            <div class="descript_block">
                <div style="width: 200px">
                    <div class="descript_block_text01" style="float: left">1</div>
                    <div class="descript_block_text02" style="float: left">录入验证号码</div>
                </div>
                <div>
                    <div class="descript_nbsp">&nbsp;</div>
                    <div class="descript_block_text03" id="inputNo">录入验证号码为32位数字与字母的组合，录入时请认真核对</div>
                </div>
            </div>
            <div class="descript_block">
                <div style="width: 200px">
                    <div class="descript_block_text01" style="float: left">2</div>
                    <div class="descript_block_text02" style="float: left">图形校验码</div>
                </div>
                <div>
                    <div class="descript_nbsp">&nbsp;</div>
                    <div class="descript_block_text03">请按图所示，输入图形验证码，如看不清请按图片刷新</div>
                </div>
            </div>
            <div class="descript_block">
                <div style="width: 200px">
                    <div class="descript_block_text01" style="float: left">3</div>
                    <div class="descript_block_text02" style="float: left">点击查询按钮</div>
                </div>
                <div>
                    <div class="descript_nbsp">&nbsp;</div>
                    <div class="descript_block_text03">纸质单据内容如有修改，请以在线验证的内容为准</div>
                </div>
            </div>
            <div class="descript_block">
                <div style="width: 200px">
                    <div class="descript_block_text01" style="float: left">4</div>
                    <div class="descript_block_text02" style="float: left">显示表单</div>
                </div>
                <div>
                    <div class="descript_nbsp">&nbsp;</div>
                    <div class="descript_block_text03" style="">本页面展示的电子表单内容仅提供给用户用于核对纸质单据的真实性</div>
                </div>
            </div>
        </div>
        <div id="footer_img2" class="footer_img2"><img class="img-responsive" src="images/N_bd04.jpg"></div>
        <div id="form" class="row" style="width:50%;margin: 30px auto;height: 160px">
          <el-form :inline="true" :model="dataForm" ref="listQuery" class="demo-form-inline">
            <div class="col-md-9">
                <div class="row form_row"><label class="col-md-4 form_row_label">验证号码：</label>
                  <el-input v-model="dataForm.bdh" id="verificationValue"
                        class="col-md-8 input_value input_value_hover" placeholder="请输入32位验证号码"></el-input>
                </div>
                <div class="row form_row"><label class="col-md-4 form_row_label">验证码：</label>
                  <el-input v-model="dataForm.code" id="captchaValue" style="border-right-width: 0"
                        placeholder="请输入图片的验证码" class="col-md-5 input_value input_value_hover"></el-input>
                    <img class="col-md-3 pad0" id="captchaImg" @click="refreshCode()" style="border: 1px solid #cbcbcb"
                         width="100" height="32" :src="src">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input_submit" style="margin-top: 65px">
                  <div @click="submintCode()">查 询</div>
                </div>
            </div>
          </el-form>
        </div>
        <div id="footer_img1" class="footer_img1"><img class="img-responsive" src="../../assets/sb/N_bd04.jpg"></div>
    </div>
</template>

<script>
  import $ from 'jquery'
  export default {
      name: "myForm",
      data() {
          return {
              src: `${this.COMMON.initUrl}/api/verify/getImgCaptchaDTO/` + $.now(),
              dataForm: {
                  bdh: this.$route.query.code,
                  code: '',
              }
          }
      },
      methods: {
          refreshCode() {
              this.src = `${this.COMMON.initUrl}/api/verify/getImgCaptchaDTO/` + $.now();
          },
          submintCode() {
              if (this.dataForm.code == '') {
                  alert('error：验证码不能为空')
                  return;
              }
              this.$axios.get("/sys/user-infor/exportPdf01", {
                  params: {
                      bdh: this.dataForm.bdh,
                      code: this.dataForm.code
                  }
              }).then(res => {
                  if (res.data.code==="600"){
                      alert('error：验证码已失效')
                      return;
                  } else if (res.data.code==="601"){
                      alert('error：验证码不正确')
                      return;
                  } else if (res.data.code==="100"){
                      const link = document.createElement("a");
                      link.download = this.dataForm.bdh + ".pdf";
                      link.style.display = 'none';
                      link.href = res.data.info.result;
                      document.body.appendChild(link);
                      link.click();
                      URL.revokeObjectURL(link.href);
                      document.body.removeChild(link);
                  }else {
                      alert('error：请求无效')
                      return;
                  }
              });
          }
      }
  }
</script>

<style>
    @import "../../assets/sb/main.css";
    @import "../../assets/sb/vendor.css";
    .el-input__inner{
      border: none !important;
      margin: 0px;
      padding: 0px;
      height: 30px;
      border-radius: 0 !important;
      background-color: transparent !important;
    }
</style>
