import router from './router'
//import store from './store'
import {getToken} from '@/utils/auth' // 验权
import {Message} from 'element-ui'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
const whiteList = ['/form','/forms','/login','/', '/404'] //白名单,不需要登录的路由

router.beforeEach((to, from, next) => {
  //console.log(to)
  //console.log(from)
  //console.log(next)
  //console.log(to.path)
  NProgress.start()
  let token = window.localStorage.getItem('auth');
  //console.log(token)
  if (getToken()&&token) {
    //console.log("aa")
    //如果已经登录
    if (to.path === '/login') {
      //console.log("aa1")
      next({path: '/'})
      NProgress.done() // 结束Progress
    } else {
      //console.log("aa2")
      next()
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    //console.log("bb")
    //如果前往的路径是白名单内的,就可以直接前往
    next()
  } else {
    //console.log("cc");
    //如果路径不是白名单内的,而且又没有登录,就跳转登录页面
    Message({
      title: '提示',
      type: "warning",
      message: '请先登录'
    });
    next('/login')
    NProgress.done() // 结束Progress
  }
  /*// 路由拦截
  if (to.path == '/') {//如果是登录直接跳转
    next();
  } else {//判断是否有token
    let token = window.localStorage.getItem('auth');
    if (token) {//判断是否有权限
      next();
    } else {//没有token跳转到首页
      Message({
        title: '提示',
        type: "warning",
        message: '请先登录'
      });
      next({
        path: '/',
        query: {
          redirect: "redirect"
        }
      })
    }
  }*/
});

router.afterEach(() => {
  NProgress.done() // 结束Progress
})
