import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from "./router"
import * as echarts from 'echarts'
import store from "@/store"
import Axios from 'axios'
import './assets/icon/iconfont.css'
import 'video.js/dist/video-js.css'
//注册全局组件
import "./components/global/index"
import '@/permission' // 权限
import '@/utils/api'
import global from '@/utils/common'
import {hasPermission} from "./utils/hasPermission";

Vue.prototype.$axios = Axios;
Axios.defaults.withCredentials = true;//请求头中加cookie
Axios.defaults.baseURL = 'http://121.199.23.236/api';
//Axios.defaults.baseURL = 'http://192.168.1.3:9095/api';

Vue.prototype.$echarts = echarts;
Vue.prototype.$pageSizes = [10,50,100,200];
Vue.prototype.$pageSize = 12;
Vue.prototype.COMMON = global;
Vue.prototype.hasPerm = hasPermission

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
